import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import logo from "../img/logo_bitter-hedgehog.png";
export default class IndexPage extends React.Component {
  render() {
    // const { data } = this.props
    // const { edges: posts } = data.allMarkdownRemark
    console.log(this.props.data.file);
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <nav className="level">
              <div className="level-item has-text-centered">
                <img style={{ maxWidth: "400px" }} src={logo} />
              </div>
            </nav>
            <div className="level" />
            {/* <h3 className="subtitle is-3 has-text-centered">
              A little prickly but a lot of fun -- that's Bitter Hedgehog
            </h3> */}
            <div className="content" style={{ position: "relative" }}>
              <div
                className="full-width-image-container margin-top-0"
                style={{
                  backgroundImage: `url(${
                    this.props.data.file.childImageSharp.fluid.src
                  })`
                }}
              />
              <h4
                className="has-text-weight-bold is-size-3"
                style={
                  {
                    position: "absolute",
                    top: 10,
                    zIndex: "2",
                    boxShadow: "0.5rem 0 0 #C0B71A, -0.5rem 0 0 #C0B71A",
                    backgroundColor: "#C0B71A",
                    color: "white",
                    padding: "1rem"
                  } // left:
                }
              >
                A little prickly but a lot of fun
              </h4>
            </div>
            {/* <section
              style={{ borderRadius: "4px" }}
              className="hero is-warning is-medium is-bold"
            >
              <div className="hero-body">
                <div className="container">
                  <h1 className="title">Bitter Hedgehog</h1>
                  <h2 className="subtitle">
                    A little prickly but a lot of fun -- that's Bitter Hedgehog.
                  </h2>
                </div>
              </div>
            </section> */}
          </div>
        </section>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    file(relativePath: { eq: "mixed_drink_2.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

// export const pageQuery = graphql`
//   query IndexQuery {
//     allMarkdownRemark(
//       sort: { order: DESC, fields: [frontmatter___date] },
//       filter: { frontmatter: { templateKey: { eq: "blog-post" } }}
//     ) {
//       edges {
//         node {
//           excerpt(pruneLength: 400)
//           id
//           fields {
//             slug
//           }
//           frontmatter {
//             title
//             templateKey
//             date(formatString: "MMMM DD, YYYY")
//           }
//         }
//       }
//     }
//   }
// `
